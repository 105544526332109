import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import './quote.scss';

const GetAQuoteHeader = () => {
  return (
    <div className='uk-position-relative uk-container'>
      <div className='uk-section uk-section-regular uk-width-1-1 uk-dark'>
        <div className='uk-flex uk-text-center uk-flex-middle uk-flex-center uk-container uk-container-small'>
          <div className='uk-width-1-1'>
            <h1 className='uk-heading-small'>
              <mark className='mark'>Get A Quote</mark>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

const QuoteForm = () => {
  return (
    <form
      name='contact-form'
      className='contact-form'
      method='POST'
      data-netlify='true'
      action='/success'
    >
      <fieldset id='form_set' className='uk-padding-remove uk-border-remove'>
        <div className='uk-margin'>
          <input
            className='uk-input uk-border-rounded uk-form-medium'
            type='text'
            name='name'
            placeholder='Name'
            required
          />
        </div>
        <div className='uk-margin'>
          <input
            className='uk-input uk-border-rounded uk-form-medium'
            type='email'
            name='email'
            placeholder='Email'
            required
          />
        </div>
        <div className='uk-margin'>
          <input
            className='uk-input uk-border-rounded uk-form-medium'
            type='phone'
            name='phone'
            placeholder='Phone'
            required
          />
        </div>
        <div className='uk-margin' id='description' style={{ display: 'none' }}>
          <input
            id='input_description'
            className='uk-input uk-border-rounded uk-form-medium'
            type='text'
            placeholder='Subject'
          />
        </div>
        <div className='uk-margin'>
          <textarea
            className='uk-textarea uk-border-rounded'
            name='body'
            placeholder='Comments'
          ></textarea>
        </div>
        <div className='uk-margin'>
          <input type='hidden' id='input_subject' name='subject' value='' />
          <input
            className='uk-button uk-button-primary uk-button-medium'
            type='submit'
            value='Send'
          />
        </div>
      </fieldset>
    </form>
  );
};

const GetAQuote = () => (
  <Layout>
    <SEO title='Quote' />
    <GetAQuoteHeader />
    <div className='uk-section uk-section-large uk-padding-remove-top'>
      <div className='uk-container uk-container-xsmall'>
        <article className='uk-article'>
          <div className='uk-article-content'>
            <QuoteForm />
          </div>
        </article>
      </div>
    </div>
  </Layout>
);

export default GetAQuote;
